import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"

import config from "../../gatsby-config"
import Layout from "../components/Layout/layout"
import CtaButton from "../components/CtaButton"
import Navigation from "../components/Layout/Navigation"
import Footer from '../components/Layout/Footer'

class Index extends React.Component {

  render() {
    return (
      <Layout>
        <React.Fragment>
          <Helmet title={config.siteMetadata.siteTitle} />
          <main>
            <IndexHeadContainer>
              <Navigation />
              <Hero>
                <h1>{config.siteMetadata.siteTitle}</h1>
                <h4>{config.siteMetadata.siteDescription}</h4>
              </Hero>
            </IndexHeadContainer>
            <BodyContainer>
              <h2>Ready to build your solution?</h2>
              <p>Review the documentation to get started.</p>
              <CtaButton to="/introduction">Go to Introduction</CtaButton>
            </BodyContainer>
            <BodyContainer>
              <h2>Looking for more?</h2>
              <p>Here are a few places to explore.</p>
              <CtaButton to="/case-integration-api">Case Integration API</CtaButton>
              <CtaButton to="/events-api">Events API</CtaButton>
              <CtaButton to="/service-partners-integration">Service Partners Integration</CtaButton>
              <CtaButton to="/digital-inbox-api">Digital Inbox API</CtaButton>
              <CtaButton to="/widget-implementation">FNOL Widget implementation</CtaButton>
            </BodyContainer>
          </main>
          <Footer />
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </React.Fragment>
      </Layout>
    );
  }
}

export default Index;

const IndexHeadContainer = styled.div`
  background: ${props => props.theme.brandHeader};
  text-align: center;
  color: #fff;

  @media screen and (min-width: 1300px) {
    background-image: url('../../../logos/Upscaled-logo-element_White.png');
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: -170px 100px;
  }
`

const Hero = styled.div`
  padding: 25px 0 75px 0;

  & > h1,
  & > h4 {
    font-weight: 600;
    color: #fff;
  }
`

const BodyContainer = styled.div`
  padding: 35px 50px;
  max-width: ${props => props.theme.contentWidthLaptop};
  margin: 0 auto;
`
